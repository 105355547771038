/* ======= BASIC RESET & LAYOUT ======= */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  line-height: 1.6;
  color: #333;
}

.App {
  text-align: center;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

/* ======= NAVIGATION & BUTTONS ======= */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #2c3e50;
  color: white;
}

.nav-brand a {
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
}

.nav-links {
  display: flex;
  gap: 1.5rem;
}

.nav-links a {
  color: white;
  text-decoration: none;
}

.nav-links a:hover, .nav-links a.active {
  color: #3498db;
}

.btn-login, .btn-logout, .cta-button, .retry-button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.btn-logout {
  background-color: #e74c3c;
}

.cta-button {
  padding: 0.75rem 1.5rem;
  font-size: 1.1rem;
  margin-top: 1rem;
}

.retry-button {
  background-color: #dc3545;
  margin-top: 10px;
}

.retry-button:hover {
  background-color: #c82333;
}

.download-button {
  display: inline-block;
  background-color: #2ecc71;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  text-decoration: none;
  margin-top: 10px;
  transition: background-color 0.3s;
}

.download-button:hover {
  background-color: #27ae60;
}

/* ======= HOME PAGE ======= */
.home-page {
  text-align: center;
  padding: 3rem 1rem;
  min-height: 70vh;
}

.home-page h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.cta-section {
  margin-top: 2rem;
}

.home-image-container {
  margin: 30px auto;
  max-width: 800px;
  text-align: center;
}

.home-feature-image {
  max-width: 60%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* ======= GALLERY PAGE ======= */
.gallery-page {
  min-height: 70vh;
}

.gallery-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  flex-wrap: wrap;
  gap: 15px;
}

.filter-buttons {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.filter-button {
  padding: 8px 16px;
  background: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
  font-weight: 500;
}

.filter-button:hover {
  background: #e0e0e0;
}

.filter-button.active {
  background: #007bff;
  color: white;
  border-color: #0069d9;
}

.sort-controls {
  display: flex;
  align-items: center;
  gap: 8px;
}

.sort-controls select {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #f8f8f8;
  cursor: pointer;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
  margin-top: 20px;
  min-height: 200px;
  position: relative;
  will-change: contents;
}

.gallery-item {
  border: 1px solid #eee;
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
  cursor: pointer;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  aspect-ratio: 1 / 1.2;
  transform: translateZ(0);
  will-change: transform;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  backface-visibility: hidden;
}

.gallery-item:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.gallery-item img, 
.gallery-item .video-thumbnail {
  width: 100%;
  height: 200px;
  object-fit: cover;
  background-color: #f8f9fa;
  opacity: 1;
  transition: filter 0.3s ease;
  will-change: filter;
  transform: translateZ(0);
  position: relative;
  z-index: 2;
  flex: 0 0 200px;
  animation: fadeIn 0.3s ease-in;
  background-color: #f0f0f0;
}

.gallery-item:hover img {
  opacity: 0.9;
}

.gallery-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200px;
  background-color: #f8f9fa;
  z-index: -1;
  background: linear-gradient(90deg, #f0f0f0 25%, #f8f8f8 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

.gallery-item.loaded .thumbnail-loading-overlay {
  display: none;
}

.document-thumbnail {
  object-fit: contain !important;
  padding: 20px;
}

.gallery-item-info {
  padding-left: 5px;
  flex: 1;
}

.item-title {
  font-weight: 500;
  margin: 0 0 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.item-details {
  font-size: 0.8rem;
  color: #666;
  margin: 0;
}

.empty-gallery {
  text-align: center;
  padding: 30px;
  background: #f8f8f8;
  border-radius: 8px;
  margin: 20px 0;
}

/* ======= THUMBNAILS ======= */
.video-thumbnail {
  position: relative;
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.video-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.thumbnail-loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200px;
  background: linear-gradient(90deg, #f0f0f0 25%, #f8f8f8 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  z-index: 1;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  pointer-events: none;
}

/* ======= PAGINATION ======= */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  margin-top: 2rem;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.pagination-button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 0.6rem 1.2rem;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 0.5rem;
  font-weight: 500;
  transition: background-color 0.2s;
}

.pagination-button:hover {
  background-color: #2980b9;
}

.pagination-button:disabled {
  background-color: #bdc3c7;
  cursor: not-allowed;
}

.page-info {
  margin: 0 1rem;
  font-weight: 500;
}

.page-numbers {
  display: flex;
  align-items: center;
  margin: 0 1rem;
}

.page-number {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  border-radius: 50%;
  border: 1px solid #ddd;
  background: white;
  cursor: pointer;
  transition: all 0.2s;
}

.page-number:hover {
  background: #e9ecef;
}

.page-number.current-page {
  background: #3498db;
  color: white;
  border-color: #3498db;
}

/* ======= MEDIA VIEWER ======= */
.media-viewer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 0; /* Remove padding to maximize space */
  opacity: 0;
  animation: fadeIn 0.2s forwards;
}

.media-viewer-container {
  background-color: white;
  border-radius: 8px;
  max-width: 30%; /* Percentage of screen width */
  max-height: 90%; /* Percentage of screen height */
  width: auto;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.3);
  transform: scale(0.95);
  opacity: 0;
  animation: scaleIn 0.3s forwards;
  overflow: hidden; /* Prevent content overflow */
}

.media-viewer-content {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0; /* Remove padding for maximum space */
  background-color: #f8f9fa;
  position: relative;
  height: auto;
  max-height: 70vh; /* Cap height */
  overflow: hidden; /* Hide overflow */
}

/* This is the key improvement */
.media-viewer-main-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.media-viewer-main-content img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* This preserves aspect ratio */
}

.media-viewer-main-content img.zoomed {
  max-width: none;
  max-height: none;
  cursor: zoom-out;
}

.zoom-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 5;
  background: rgba(0,0,0,0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.media-viewer-details {
  padding: 12px;
  background: #fff;
  flex-shrink: 0; /* Prevent details from shrinking */
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1010;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.2s;
}

.close-button:hover {
  background-color: rgba(0, 0, 0, 0.9);
}

.media-viewer-image {
  max-width: 100%;
  max-height: 60vh; /* Reduced from 70vh */
  object-fit: contain;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.media-viewer-video {
  width: auto;
  height: auto;
  max-width: 95vw;
  max-height: 85vh;
}

.media-viewer-pdf {
  width: 90vw;
  height: 85vh;
  max-width: 1200px;
  border: none;
}

.media-viewer-main-content img {
  max-width: 80vw; /* Reduced from 95vw */
  max-height: 65vh; /* Reduced from 85vh */
  object-fit: contain;
}

.media-date {
  color: #7f8c8d;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

.nav-button {
  position: absolute;
  top: 50%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  font-size: 32px;
  height: 60px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0.7;
  z-index: 5;
  transform: translateY(-50%);
  transition: opacity 0.2s, background-color 0.2s;
}

.nav-button:hover {
  opacity: 1;
  background: rgba(0, 0, 0, 0.7);
}

.nav-prev {
  left: 0;
  border-radius: 0 4px 4px 0;
}

.nav-next {
  right: 0;
  border-radius: 4px 0 0 4px;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .media-viewer-container {
    max-width: 95vw;
    max-height: 90vh;
  }
  
  .media-viewer-content {
    padding: 0.5rem;
  }
  
  .media-viewer-main-content img {
    max-width: 90vw;
  }
}

/* ======= FILE UPLOAD ======= */
.upload-section {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.upload-section h2 {
  margin-bottom: 1rem;
}

.file-input-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.file-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input-label {
  background-color: #3498db;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.file-input-label:hover {
  background-color: #2980b9;
}

.selected-files {
  color: #555;
}

.upload-button {
  background-color: #2ecc71;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
}

.upload-button:disabled {
  background-color: #95a5a6;
  cursor: not-allowed;
}

.upload-progress {
  margin: 1rem 0;
}

.progress-bar {
  width: 100%;
  height: 10px;
  background-color: #ecf0f1;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 0.5rem;
}

.progress-bar-fill {
  height: 100%;
  background-color: #3498db;
  transition: width 0.3s ease;
}

.upload-success {
  background-color: #d4edda;
  color: #155724;
  padding: 0.75rem;
  border-radius: 4px;
  margin: 1rem 0;
}

.upload-error {
  background-color: #f8d7da;
  color: #721c24;
  padding: 0.75rem;
  border-radius: 4px;
  margin: 1rem 0;
}

.file-list {
  margin: 1rem 0;
}

.file-list ul {
  list-style: none;
  padding: 0;
}

.file-list li {
  padding: 0.5rem 0;
  border-bottom: 1px solid #eee;
}

.download-error {
  color: #721c24;
  background-color: #f8d7da;
  padding: 8px;
  border-radius: 4px;
  margin-top: 8px;
  font-size: 0.9rem;
}

/* ======= LOADING & ERROR STATES ======= */
.loading-indicator {
  text-align: center;
  padding: 3rem;
  color: #7f8c8d;
  font-size: 1.2rem;
  animation: pulse 1.5s infinite;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #007bff;
  animation: spinner 1s linear infinite;
  margin: 0 auto 10px;
}

.error-message {
  background-color: #f8d7da;
  color: #721c24;
  padding: 1.5rem;
  border-radius: 8px;
  margin: 1.5rem 0;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.error-message p {
  margin-bottom: 1rem;
}

.media-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #666;
  font-size: 14px;
  padding: 20px;
}

.media-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #721c24;
  background-color: #f8d7da;
  padding: 20px;
  border-radius: 4px;
  text-align: center;
}

.loading-image {
  opacity: 0.7;
  filter: blur(1px);
  transition: opacity 0.3s, filter 0.3s;
}

/* ======= PROFILE & NOT FOUND PAGES ======= */
.profile-page {
  max-width: 600px;
  margin: 0 auto;
}

.profile-card {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-top: 2rem;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.profile-image img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
}

.not-found-page {
  text-align: center;
  padding: 3rem 1rem;
}

.home-link {
  display: inline-block;
  margin-top: 1rem;
  color: #3498db;
  text-decoration: none;
}

/* ======= FOOTER ======= */
.footer {
  text-align: center;
  padding: 1.5rem;
  background-color: #2c3e50;
  color: white;
}

/* ======= ANIMATIONS ======= */
@keyframes pulse {
  0% { opacity: 0.6; }
  50% { opacity: 1; }
  100% { opacity: 0.6; }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes scaleIn {
  to { 
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes spinner {
  to {transform: rotate(360deg);}
}

@keyframes shimmer {
  0% { background-position: -200% 0; }
  100% { background-position: 200% 0; }
}

/* ======= RESPONSIVE STYLES ======= */
@media (max-width: 768px) {
  .gallery-controls {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .filter-buttons {
    margin-bottom: 10px;
  }
}

@media (hover: none) {
  .nav-button {
    opacity: 0.9;
  }
}